import React, { useEffect, useState, useMemo } from "react";
import { IonLoading, IonToast } from "@ionic/react";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import { useTranslation } from "react-i18next";
import PageLayout from "../../layouts/PageLayout";
// import Carousel from "./carousel/Carousel";
import DevicesDisplay from "../internet/components/DevicesDisplay";
import DevicesDisplayBasic from "../internet/components/DevicesDisplayBasic";
import ProfileHub from "./profiles/ProfileHub";
import StreamingRelocationCard from "./streaming/StreamingRelocationCard";
import classNames from "classnames";
import { Dispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import Title from "components/Title";
import NavigationLink from "components/NavigationLink";
import SettingsRow from "components/SettingsRow";
import NewModal from "components/NewModal";
import { Subscription } from "model";
import { close } from "ionicons/icons";
import api from "../../api";
import { tryCatch } from "../../utils";

interface Props {
  adapterId: string;
}

const Home: React.FC<Props> = ({ adapterId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch>();

  const [
    resetAdapterModalWarningIsActive,
    setResetAdapterModalWarningIsActive,
  ] = useState<boolean>(false);
  const [adapterResetInProgress, setAdapterResetInProgress] = useState<boolean>(
    false
  );

  const [timezone, setTimezone] = useState<string>("");
  const [expiringMessage, setExpiringMessage] = useState<string>("");
  const [accountMessage, setAccountMessage] = useState<string>("");

  const { adapter, subscriptions, subscription } = useSelector(
    (state: RootState) => {
      const adapter = state.adapter.adapter!;
      const subscriptions = state.adapter.subscriptions;
      const subscription = adapter
        ? state.adapter.subscriptions.find(
            (sub: Subscription) =>
              sub.adapter_id && sub.adapter_id === adapter.id
          )
        : undefined;
      return {
        adapter,
        subscriptions,
        subscription,
      };
    }
  );
  const isAdapterOnline = adapter.services.online.state;

  useEffect(() => {
    getAccountMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const expiringSub = subscriptions.find(
      (sub: Subscription) =>
        sub.default_payment_method === null &&
        new Date(sub.current_period_end * 1000).getTime() -
          new Date().getTime() <
          1000 * 3600 * 24 * 30
    );

    if (expiringSub) {
      const daysLeft = Math.ceil(
        (new Date(expiringSub.current_period_end * 1000).getTime() -
          new Date().getTime()) /
          (1000 * 3600 * 24)
      );
      setExpiringMessage(
        `You have a subscription that will be interrupted in under ${daysLeft} days. Please <a href='/account/payment-method/${expiringSub.subscription_id}'>Add a payment method</a>!`
      );
    }
  }, [subscriptions]);

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "home" });
    setTimezone(adapter ? adapter.timezone : "");
  }, [adapter]);

  useMemo(() => {
    if (isAdapterOnline) {
      setAdapterResetInProgress(false);
    }
  }, [isAdapterOnline]);

  const getAccountMessage = async () => {
    let response = await tryCatch(api.account.getAccountMessage());
    if (response.error) {
      console.log("Error getting one time message: ", response.error);
    } else {
      if (response.result && response.result.data) {
        const stringName = response.result.data.string_name;
        if (stringName.length > 0) {
          setAccountMessage(t(`messages:${stringName}`));
        }
      }
    }
  };

  const onCancelAdapterResetModalHandler = () => {
    setResetAdapterModalWarningIsActive(false);
  };
  const onResetAdapterHandler = () => {
    setResetAdapterModalWarningIsActive(false);
    setAdapterResetInProgress(true);
    dispatch.adapter.resetAdapter(adapter.id);
  };

  const RestartButtonComponent = (
    <button
      className={classNames(
        "bg-white rounded-md font-medium focus:outline-none focus:ring-2 focus:ring-offset-2",
        isAdapterOnline
          ? "text-orange hover:text-orange-600 focus:ring-orange"
          : "text-gray-400 focus:ring-gray cursor-default"
      )}
      onClick={() =>
        isAdapterOnline && setResetAdapterModalWarningIsActive(true)
      }
    >
      {t("settings:restartRouter")}
    </button>
  );

  return (
    <PageLayout testId="home-screen">
      {/* <Carousel /> */}

      <Title title={t("home:settings")} className="mt-7" />
      <dl className="divide-y divide-gray-200">
        <SettingsRow
          title={t("settings:routerName")}
          updateText={t("settings:update")}
          value={adapter.name}
          updateLink="/settings/rename"
        />
        <SettingsRow
          title={t("settings:timezone")}
          updateText={t("settings:update")}
          value={timezone}
          updateLink="/settings/timezone"
        />
        <SettingsRow
          title={t("settings:routerStatus")}
          value={
            isAdapterOnline
              ? t("settings:routerOnlineStatus")
              : t("settings:routerDownStatus")
          }
          updateComponent={RestartButtonComponent}
        />
      </dl>

      {subscription &&
      subscription.product_permissions["PROFILES"] &&
      subscription.product_permissions["PROFILES"].toLowerCase() === "true" ? (
        <ProfileHub adapterId={adapterId} />
      ) : null}

      {subscription &&
      subscription.product_permissions["DEVICES"] &&
      subscription.product_permissions["DEVICES"].toLowerCase() === "true" ? (
        <DevicesDisplay />
      ) : (
        <DevicesDisplayBasic />
      )}

      {subscription &&
      subscription.product_permissions["DATA"] &&
      subscription.product_permissions["DATA"].toLowerCase() === "true" ? (
        <NavigationLink
          to="/internet"
          title={t("home:pressable:seeNetworkUsage")}
        />
      ) : null}

      {subscription &&
      subscription.product_permissions["STREAMING_RELOCATION"] &&
      subscription.product_permissions["STREAMING_RELOCATION"].toLowerCase() ===
        "true" ? (
        <StreamingRelocationCard />
      ) : null}

      <NewModal
        type="warning"
        open={resetAdapterModalWarningIsActive}
        onClose={onCancelAdapterResetModalHandler}
        onOk={onResetAdapterHandler}
        title={t("settings:resetAdapterConfirmationModalTitle")}
        description={t("settings:resetAdapterConfirmationModalCopy")}
        okText="Yes"
        cancelText="Cancel"
        okTestId="reset-ok"
      />
      {adapterResetInProgress && isAdapterOnline && (
        <IonLoading isOpen message="Loading" />
      )}
      <IonToast
        color="success"
        isOpen={accountMessage.length > 0}
        onDidDismiss={() => setAccountMessage("")}
        message={accountMessage}
        duration={10000}
        buttons={[
          {
            icon: close,
            role: "cancel",
          },
        ]}
      />
      <IonToast
        color="warning"
        isOpen={accountMessage.length === 0 && expiringMessage.length > 0}
        onDidDismiss={() => setExpiringMessage("")}
        message={expiringMessage}
        duration={10000}
        buttons={[
          {
            icon: close,
            role: "cancel",
          },
        ]}
      />
    </PageLayout>
  );
};

export default Home;
