import Button from "components/Button";
import Title from "components/Title";
import Checkbox from "components/Checkbox";
import React, { useEffect, useState } from "react";
import { UnclaimedCodes } from "model";
import api from "../../api";
import { tryCatch } from "../../utils";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import PageLayout from "../../layouts/PageLayout";
import OnboardingSteps from "./components/OnboardingSteps";

const AllSetContainer: React.FC = () => {
  const { t } = useTranslation("onboarding");
  const history = useHistory();
  const [unclaimedCodes, setUnclaimedCodes] = useState<UnclaimedCodes[]>([]);
  const [newsletterToggle, setNewsletterToggle] = useState<boolean>(false);

  useEffect(() => {
    getNewCodeList();
  }, []);

  const getNewCodeList = async () => {
    let codeListRes = await tryCatch(api.subscription.getCodesList());
    if (codeListRes.error) {
      console.log("Error fetching codes: ", codeListRes.error);
    } else {
      if (
        codeListRes.result &&
        codeListRes.result.data &&
        codeListRes.result.data.unclaimed_codes
      ) {
        let unclaimedCodes: UnclaimedCodes[] = [];
        codeListRes.result.data.unclaimed_codes.forEach((unclaimed_codes) => {
          unclaimed_codes.code_types.forEach((code_type) => {
            if (code_type === "NordVPN 1 Year") {
              unclaimedCodes.push(unclaimed_codes);
            }
          });
        });
        setUnclaimedCodes(unclaimedCodes);
      }
    }
  };

  const handleClaimCode = async () => {
    const apiResult = await tryCatch(
      api.subscription.claimCode(unclaimedCodes[0].adapter_id, "NordVPN 1 Year")
    );

    if (apiResult.error) {
      console.log("Error claiming code: ", apiResult.error.message);
    } else {
      if (apiResult.result.data.result === "Code claimed") {
        window.location.href = "/account?reason=code_claimed";
      } else {
        console.log("Error claiming code: ", apiResult.result.data.result);
      }
    }
  };

  const handleOnboardingComplete = async (newsletter: boolean) => {
    if (newsletter) {
      const apiResult = await tryCatch(
        api.account.setNewsletterFlag(newsletter)
      );
      if (apiResult.error) {
        console.log(
          "Error updating newsletter flag: ",
          apiResult.error.message
        );
      } else {
        history.push("/");
      }
    } else {
      history.push("/");
    }
  };

  return (
    <PageLayout>
      <OnboardingSteps stage="allSet" />
      <Title title={t("allSet.title")} className="mb-4" />
      <p>{t("allSet.description")}</p>
      {unclaimedCodes.length > 0 && (
        <p className="mt-5">{t("allSet.nordCodeAvailable")}</p>
      )}
      {unclaimedCodes.length > 0 && (
        <Button
          type="button"
          onClick={() => {
            handleClaimCode();
          }}
          className="mt-2"
        >
          {t("allSet.claimNord")}
        </Button>
      )}
      <div style={{ padding: "20px 0px 5px" }}>
        <Checkbox
          id={"newsletterToggle"}
          checked={newsletterToggle}
          change={() => {
            setNewsletterToggle(!newsletterToggle);
          }}
          label={t("allSet.newsletterMessage")}
        />
      </div>
      <Button
        type="button"
        onClick={() => {
          handleOnboardingComplete(newsletterToggle);
        }}
        className="mt-5"
      >
        {t("allSet.goHome")}
      </Button>
    </PageLayout>
  );
};
export default AllSetContainer;
