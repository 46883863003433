import http from "axios";
import { ACCOUNT_MESSAGE_ROUTE, NEWSLETTER_FLAG_ROUTE } from "../constants";

interface MessageResponse {
  string_name: string;
}

export function getAccountMessage() {
  return http.get<MessageResponse>(ACCOUNT_MESSAGE_ROUTE);
}

interface NewsletterFlagBody {
  subscribed?: boolean;
}

interface NewsletterFlagResponse {
  subscribed: boolean;
}

export function setNewsletterFlag(subscribed?: boolean) {
  const newsletterFlagBody: NewsletterFlagBody = {
    subscribed,
  };

  return http.post<NewsletterFlagResponse>(
    NEWSLETTER_FLAG_ROUTE,
    newsletterFlagBody
  );
}
