import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import AppConfig from "./../AppConfig";
// import LanguageDetector from "i18next-browser-languagedetector";
import login from "utils/i18n/login.jsonc";
import createAccount from "utils/i18n/createAccount.jsonc";
import home from "utils/i18n/home.jsonc";
import securitySettings from "utils/i18n/securitySettings.jsonc";
import primaryNetwork from "utils/i18n/primaryNetwork.jsonc";
import guestNetwork from "utils/i18n/guestNetwork.jsonc";
import editNetwork from "utils/i18n/editNetwork.jsonc";
import editAccount from "utils/i18n/editAccount.jsonc";
import generic from "utils/i18n/generic.jsonc";
import internet from "utils/i18n/internet.jsonc";
import invoices from "utils/i18n/invoices.jsonc";
import devices from "utils/i18n/devices.jsonc";
import profiles from "utils/i18n/profiles.jsonc";
import profileOverview from "utils/i18n/profileOverview.jsonc";
import deviceOverview from "utils/i18n/deviceOverview.jsonc";
import manageDevices from "utils/i18n/manageDevices.jsonc";
import manageSub from "utils/i18n/manageSub.jsonc";
import account from "utils/i18n/account.jsonc";
import settings from "utils/i18n/settings.jsonc";
import streaming from "utils/i18n/streaming.jsonc";
import dataCap from "utils/i18n/dataCap.jsonc";
import profileEditCreate from "utils/i18n/profileEditCreate.jsonc";
import editDevice from "utils/i18n/editDevice.jsonc";
import resetPassword from "utils/i18n/resetPassword.jsonc";
import routers from "utils/i18n/routers.jsonc";
import codes from "utils/i18n/codes.jsonc";
import admin from "utils/i18n/admin.jsonc";
import onboarding from "utils/i18n/onboarding.jsonc";
import subscribe from "utils/i18n/subscribe.jsonc";
import upgrade from "utils/i18n/upgrade.jsonc";
import purchase from "utils/i18n/purchase.jsonc";
import redemption from "utils/i18n/redemption.jsonc";
import paymentMethod from "utils/i18n/paymentMethod.jsonc";
import messages from "utils/i18n/messages.jsonc";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //   .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: AppConfig.env !== "prod",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        // translation: ui,
      },
    },
  });

i18n.addResourceBundle("en", "login", login, true);
i18n.addResourceBundle("en", "createAccount", createAccount, true);
i18n.addResourceBundle("en", "home", home, true);
i18n.addResourceBundle("en", "securitySettings", securitySettings, true);
i18n.addResourceBundle("en", "primaryNetwork", primaryNetwork, true);
i18n.addResourceBundle("en", "guestNetwork", guestNetwork, true);
i18n.addResourceBundle("en", "editNetwork", editNetwork, true);
i18n.addResourceBundle("en", "editAccount", editAccount, true);
i18n.addResourceBundle("en", "generic", generic, true);
i18n.addResourceBundle("en", "internet", internet, true);
i18n.addResourceBundle("en", "invoices", invoices, true);
i18n.addResourceBundle("en", "devices", devices, true);
i18n.addResourceBundle("en", "profiles", profiles, true);
i18n.addResourceBundle("en", "profileOverview", profileOverview, true);
i18n.addResourceBundle("en", "deviceOverview", deviceOverview, true);
i18n.addResourceBundle("en", "manageDevices", manageDevices, true);
i18n.addResourceBundle("en", "manageSub", manageSub, true);
i18n.addResourceBundle("en", "account", account, true);
i18n.addResourceBundle("en", "settings", settings, true);
i18n.addResourceBundle("en", "streaming", streaming, true);
i18n.addResourceBundle("en", "dataCap", dataCap, true);
i18n.addResourceBundle("en", "profileEditCreate", profileEditCreate, true);
i18n.addResourceBundle("en", "editDevice", editDevice, true);
i18n.addResourceBundle("en", "resetPassword", resetPassword, true);
i18n.addResourceBundle("en", "routers", routers, true);
i18n.addResourceBundle("en", "codes", codes, true);
i18n.addResourceBundle("en", "admin", admin, true);
i18n.addResourceBundle("en", "onboarding", onboarding, true);
i18n.addResourceBundle("en", "subscribe", subscribe, true);
i18n.addResourceBundle("en", "upgrade", upgrade, true);
i18n.addResourceBundle("en", "purchase", purchase, true);
i18n.addResourceBundle("en", "redemption", redemption, true);
i18n.addResourceBundle("en", "paymentMethod", paymentMethod, true);
i18n.addResourceBundle("en", "messages", messages, true);

export default i18n;
