import * as account from "./account";
import * as adapter from "./adapter";
import * as auth from "./auth";
import * as profile from "./profile";
import * as device from "./device";
import * as streaming from "./streaming";
import * as subscription from "./subscription";
import * as vpn from "./vpn";

const api = {
  account,
  adapter,
  auth,
  device,
  profile,
  streaming,
  subscription,
  vpn,
};

export default api;
