
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "title": "Security & Privacy",
  "vpn": "VPN",
  "malware": "Malware & Phishing",
  "adBlocking": "Ad Blocking",
  "vpn_killswitch":"VPN Killswitch",
  "vpnDown": "Our VPN tunnel is down, VPN is off and can't be configured right now",
  "chromeExtension": "Link to Browser Plugin (Chrome)",
  "safariExtension": "Link to Browser Plugin (Safari)",
  "validationError": "Provided value is not valid",
  "faq": {
    "title": "FAQ",
    "visitFullBase": "Visit Full Knowledge Base"
  },
  "vpnProviderSelect": "Service Provider",
  "connStatus": "Status",
  "pheroVpnMessage": "Your account level does not include Privacy Hero VPN services.\n\n Click the Upgrade button below to view your upgrade options.",
  "dragWireguard": "Drag your WireGuard .conf configuration file here or click to browse...",
  "dragOpenvpn": "Drag your OpenVPN .ovpn configuration file here or click to browse...",
  "enterManually": " to enter your configuration manually",
  "uploadFile": " to choose a configuration file"
}
