import React, { useEffect, useState } from "react";
import { Dispatch, RootState } from "../../store";
import { IonLoading } from "@ionic/react";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Title from "components/Title";
import NavigationLink from "components/NavigationLink";
import Button from "components/Button";
import Select from "components/Select";
import Input from "components/Input";
import { BetterModal, Toggle } from "components";
import { classNames } from "utils";
import { Subscription, AdapterListItem } from "model";
import api from "../../api";
import { tryCatch } from "../../utils";

///////////////////////////////////////////////////////////////
//   MAIN COMPONENT
///////////////////////////////////////////////////////////////

const RoutersContainer: React.FC<{}> = () => {
  const dispatch = useDispatch<Dispatch>();
  const [betaModalOpen, setBetaModalOpen] = useState<boolean>(false);
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [modalAdapter, setModalAdapter] = useState<AdapterListItem | undefined>(
    undefined
  );
  const [modalSub, setModalSub] = useState<Subscription | undefined>(undefined);
  const [resettingAdapterId, setResettingAdapterId] = useState<
    string | undefined
  >(undefined);
  const { t } = useTranslation("routers");
  const { adapters, subscriptions } = useSelector((state: RootState) => ({
    adapters: state.adapter.list,
    subscriptions: state.adapter.subscriptions,
  }));
  const [prices, setPrices] = useState<any>(null);

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "routers" });
    // eslint-disable-next-line react-hooks/exhaustive-deps

    getConfig();
  }, []);

  const getConfig = async () => {
    const apiResult = await tryCatch(api.subscription.getConfig());

    if (apiResult.error) {
      console.warn(apiResult.error.message);
    } else {
      setPrices(apiResult.result.data.prices);
    }
  };

  const onBetaSubmit = async (adapter_id: string, enabled: boolean) => {
    const apiResult = await tryCatch(api.adapter.beta(adapter_id, enabled));

    if (apiResult.error) {
      console.warn(apiResult.error.message);
    } else {
      dispatch.adapter.getAdapters();
      setBetaModalOpen(false);
      setModalAdapter(undefined);
      setResettingAdapterId(adapter_id);
    }
  };

  const onCancelStart = (subscription: Subscription) => {
    setModalSub(subscription);
    setCancelModalOpen(true);
  };

  const onCancelSubmit = async (
    subscription: Subscription,
    comment: string,
    reason: string
  ) => {
    const apiResult = await tryCatch(
      api.subscription.cancelSubscription(
        subscription.subscription_id,
        comment,
        reason
      )
    );

    if (apiResult.error) {
      console.warn(apiResult.error.message);
    } else {
      setCancelModalOpen(false);
      setModalSub(undefined);
      window.location.href = "/account?reason=subscription_canceled";
      console.log(
        "cancelSubscription returned " + apiResult.result.data.result
      );
    }
  };

  return (
    <div className="py-0 px-8 mx-[-32px]">
      <Title
        title={t("title")}
        subtitle={`${adapters.length} ${t("subtitle")}`}
        className="mt-7"
      />
      <ul className="list-none pl-0">
        {adapters.map((adapter) => (
          <AdapterRow
            adapter={adapter}
            subscriptions={subscriptions}
            prices={prices}
            onToggleBeta={() => {
              setModalAdapter(adapter);
              setBetaModalOpen(true);
            }}
            onUpgrade={() =>
              (window.location.href = `/account/manage/${adapter.id}`)
            }
            onCancel={(subscription: Subscription) => {
              onCancelStart(subscription);
            }}
            onRenew={async (subscription: Subscription) => {
              const apiResult = await tryCatch(
                api.subscription.renewSubscription(subscription.subscription_id)
              );

              if (apiResult.error) {
                console.warn(apiResult.error.message);
              } else {
                window.location.href = "/account?reason=subscription_renewed";
                console.log(
                  "renewSubscription returned " + apiResult.result.data.result
                );
              }
            }}
            onOnlineStateChanged={(online: boolean) => {
              if (resettingAdapterId === adapter.id && !online) {
                setResettingAdapterId(undefined);
              }
            }}
          />
        ))}
      </ul>
      <NavigationLink
        to="/onboarding/get-connected"
        title={t("activateNewRouter")}
      />
      <BetterModal
        isOpen={betaModalOpen}
        onClose={() => setBetaModalOpen(false)}
      >
        <BetaModalContent
          onOk={onBetaSubmit}
          onClose={() => setBetaModalOpen(false)}
          adapter={modalAdapter}
        />
      </BetterModal>
      <BetterModal
        isOpen={cancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
      >
        <CancelModalContent
          onCancelSubmit={onCancelSubmit}
          onClose={() => setCancelModalOpen(false)}
          subscription={modalSub}
        />
      </BetterModal>
      {/*resettingAdapterId && <IonLoading isOpen message="Resetting..." />*/}
    </div>
  );
};

///////////////////////////////////////////////////////////////
//   Beta Modal
///////////////////////////////////////////////////////////////

interface BetaModalContentProps {
  onOk(mac: string, enabled: boolean): void;
  onClose(): void;
  adapter: AdapterListItem | undefined;
}

const BetaModalContent: React.FC<BetaModalContentProps> = ({
  onOk,
  onClose,
  adapter,
}) => {
  const { t } = useTranslation("routers");
  const enabled =
    adapter &&
    adapter.fw_status &&
    adapter.fw_status.release_channel.includes("beta");

  return (
    <>
      <div className="pb-1 border-b border-solid border-gray-200 text-lg font-semibold">
        {t("betaModalTitle")}
      </div>
      <div className="flex flex-col pt-5 gap-3">
        <p className="text-sm">
          {enabled ? t("betaModalDisableText") : t("betaModalEnableText")}{" "}
          {adapter?.name} (MAC: {adapter?.hw.mac})
        </p>
        {enabled && <p className="text-sm">{t("betaModalWarning")}</p>}
        <p className="text-sm">
          {adapter?.services.online.state
            ? t("betaModalRestartRequired")
            : t("betaModalOnlineRequired")}
        </p>
      </div>
      <div className="flex flex-row justify-center pt-5 gap-3">
        <Button size="small" onClick={() => onClose()}>
          {t("betaModalCancel")}
        </Button>
        <Button size="small" onClick={() => onOk(adapter!.id, !enabled)}>
          {adapter?.services.online.state
            ? t("betaModalConfirmAndRestart")
            : t("betaModalConfirm")}
        </Button>
      </div>
    </>
  );
};

///////////////////////////////////////////////////////////////
//   Cancel Modal
///////////////////////////////////////////////////////////////

interface CancelModalContentProps {
  onCancelSubmit(
    subscription: Subscription,
    comment: string,
    reason: string
  ): void;
  onClose(): void;
  subscription?: Subscription;
}

const CancelModalContent: React.FC<CancelModalContentProps> = ({
  onCancelSubmit,
  onClose,
  subscription,
}) => {
  const [reasonCategory, setReasonCategory] = useState<string>("");
  const [reasonText, setReasonText] = useState<string>("");
  const [formError, setFormError] = useState<string | null>(null);
  const { t } = useTranslation("routers");

  return (
    <div style={defaultStyle.cancelModalContainer}>
      <div style={defaultStyle.titleContainer}>
        <h3
          className={"ml-2 mt-2 leading-6 font-medium text-gray-900 capitalize"}
        >
          {t("cancelModalTitle")}
        </h3>
      </div>
      <div style={defaultStyle.formContainer}>
        <div style={defaultStyle.formText}>{t("cancelFormText")}</div>
        <Select
          label={t("cancelReasonTitle")}
          inputProps={{
            onChange: (e: any) => setReasonCategory(e.target.value),
          }}
          id="cancelReasonCategory"
          className="max-w-xs"
          options={[
            { label: "Please select an option from below", value: "" },
            {
              label: "Customer service was less than expected",
              value: "customer_service",
            },
            { label: "Quality was less than expected", value: "low_quality" },
            { label: "Some features are missing", value: "missing_features" },
            {
              label: "Ease of use was less than expected",
              value: "too_complex",
            },
            { label: "It's too expensive", value: "too_expensive" },
            { label: "I don't use the service enough", value: "unused" },
            {
              label: "I'm switching to a different service",
              value: "switched_service",
            },
            { label: "Other reason", value: "other" },
          ]}
        />
        <Input
          id={"cancelReasonText"}
          data-testid={"cancelReasonText"}
          label={t("cancelTextTitle")}
          inputProps={{
            value: reasonText,
            onChange: (e: any) => setReasonText(e.target.value),
          }}
          error=""
          type="text"
          placeholder=""
          useTextArea={true}
        />
      </div>
      <div style={defaultStyle.buttonContainer}>
        <Button
          data-testid="cancelModalDismiss"
          size="small"
          onClick={() => {
            onClose();
          }}
        >
          {t("cancelModalDismiss")}
        </Button>
        <Button
          data-testid="cancelModalSubmit"
          size="small"
          onClick={() => {
            onCancelSubmit(subscription!, reasonText, reasonCategory);
          }}
          disabled={reasonCategory === ""}
        >
          {t("cancelModalSubmit")}
        </Button>
      </div>
    </div>
  );
};

const defaultStyle = {
  cancelModalContainer: {
    width: "450px",
  },
  titleContainer: {
    borderBottom: "1px solid #d8d8d8",
    paddingBottom: "3px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    paddingTop: "20px",
  },
  formContainer: {
    paddingTop: "20px",
    display: "flex",
    flexDirection: "column" as "column",
    gap: "20px",
  },
  formText: {
    lineHeight: "20px",
  },
};

///////////////////////////////////////////////////////////////
//   Adapter Rows
///////////////////////////////////////////////////////////////

interface AdapterRowProps {
  adapter: AdapterListItem;
  subscriptions: Subscription[];
  prices: any[];
  onToggleBeta(): void;
  onUpgrade(): void;
  onCancel(subscription: Subscription): void;
  onRenew(subscription: Subscription): void;
  onOnlineStateChanged(online: boolean): void;
}

const AdapterRow: React.FC<AdapterRowProps> = ({
  adapter,
  subscriptions,
  prices,
  onToggleBeta,
  onUpgrade,
  onCancel,
  onRenew,
  onOnlineStateChanged,
}) => {
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [scheduledPrice, setScheduledPrice] = useState<any | null>(null);
  const { t } = useTranslation("routers");

  useEffect(() => {
    onOnlineStateChanged(adapter.services.online.state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adapter.services.online.state]);

  useEffect(() => {
    if (adapter && adapter.id) {
      let adapterSub = subscriptions.find(
        (sub: Subscription) => sub.adapter_id === adapter.id
      );
      if (adapterSub) {
        setSubscription(adapterSub);
      }
    }
  }, [adapter, subscriptions]);

  useEffect(() => {
    if (prices && subscription && subscription.scheduled_plan) {
      let price = prices.find(
        (price: any) => price.id === subscription.scheduled_plan
      );
      if (price) {
        setScheduledPrice(price);
      }
    }
  }, [prices, subscription]);

  const onUpdateSubscription = async () => {
    if (subscription) {
      onUpgrade();
    }
  };

  return (
    <div
      key={`${adapter.name}-${Date.now()}`}
      className="py-3 my-2 rounded border-b-2 border-solid border-gray-100 items-center"
    >
      <div className="text-sm">
        <p className="py-1 font-medium text-gray-900">{adapter.name}</p>
        <div
          className="text-gray-500"
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "3px",
              flexDirection: "column" as "column",
            }}
          >
            <span>
              <span
                className={classNames(
                  adapter.services.online.state
                    ? "bg-green-100"
                    : "bg-gray-100",
                  "h-4 w-4 rounded-full inline-flex items-center justify-center mr-1"
                )}
                aria-hidden="true"
              >
                <span
                  className={classNames(
                    adapter.services.online.state
                      ? "bg-green-400"
                      : "bg-gray-400",
                    "h-2 w-2 rounded-full"
                  )}
                />
              </span>
              {adapter.services.online.state ? t("online") : t("offline")}
            </span>
            <p className="sm:inline">MAC: {adapter.hw.mac}</p>
            <p className="sm:inline">
              {t("activated")}:{" "}
              {new Date(adapter.registered).toLocaleDateString()}
            </p>
            <p className="sm:inline">
              {t("type")}: {adapter.hw.type}
            </p>
            <p className="sm:inline">
              {t("version")}:{" "}
              <p className="sm:inline text-xs">
                {adapter.fw_status?.fw.os?.version.substring(
                  0,
                  adapter.fw_status?.fw.os?.version.indexOf("[") - 1
                ) || "Unknown"}
              </p>
            </p>
            <div>
              <Toggle
                id="betaOptIn"
                checked={
                  adapter.fw_status
                    ? adapter.fw_status.release_channel.includes("beta")
                    : false
                }
                onChange={(state) => onToggleBeta()}
                disabled={adapter.fw_status == null}
              />
              <p className="sm:inline ml-2 h-6 content-center justify-center justify-items-center text-center">
                {t("beta")}{" "}
                <a
                  href="https://www.privacyhero.com/latest-features/"
                  target="_blank"
                  rel="noreferrer"
                  className="group transition text-xs text-gray-500 hover:text-gray-900 border-b border-solid border-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 rounded-sm"
                >
                  {t("learnMore")}
                </a>
              </p>
            </div>
          </div>
          {subscription ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column" as "column",
                gap: "3px",
                alignItems: "end",
                flexGrow: 2,
              }}
            >
              <p className="sm:inline">
                {t("plan")}: {subscription.product_name}{" "}
                {subscription.status === "trialing"
                  ? "(" + t("trialing") + ")"
                  : null}
              </p>
              <p className="sm:inline">
                {subscription.cancel_at_period_end
                  ? t("cancel")
                  : subscription.scheduled_plan
                  ? t("change") +
                    " " +
                    scheduledPrice?.product?.name +
                    " (" +
                    (scheduledPrice?.recurring?.interval === "month"
                      ? "monthly"
                      : "yearly") +
                    ") on "
                  : subscription.plan_interval === "month"
                  ? t("renewMonthly")
                  : t("renewYearly")}{" "}
                {new Date(
                  subscription.current_period_end * 1000
                ).toLocaleDateString()}
              </p>
              <div style={{ display: "flex", gap: "10px" }}>
                <Button
                  data-testid="updatePaymentMethod"
                  size="small"
                  onClick={() => {
                    window.location.href = `/account/payment-method/${subscription.subscription_id}`;
                  }}
                >
                  {t("updatePaymentMethod")}
                </Button>
                {!subscription.cancel_at_period_end ? (
                  <Button
                    data-testid="updateSubscription"
                    size="small"
                    onClick={() => {
                      onUpdateSubscription();
                    }}
                  >
                    {t("updateSubscription")}
                  </Button>
                ) : null}
                {!subscription.cancel_at_period_end ? (
                  <Button
                    data-testid="cancelSubscription"
                    size="small"
                    onClick={() => {
                      onCancel(subscription);
                    }}
                  >
                    {t("cancelSubscription")}
                  </Button>
                ) : null}
                {subscription.cancel_at_period_end ? (
                  <Button
                    data-testid="renewSubscription"
                    size="small"
                    onClick={() => {
                      onRenew(subscription);
                    }}
                  >
                    {t("renewSubscription")}
                  </Button>
                ) : null}
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column" as "column",
                gap: "3px",
                alignItems: "end",
                flexGrow: 2,
              }}
            >
              <p className="sm:inline">
                {t("plan")}: {t("free")}
              </p>
              <Button
                data-testid="subscribe"
                size="small"
                onClick={() => {
                  window.location.href = `/account/subscribe/${adapter.id}`;
                }}
              >
                {t("subscribe")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoutersContainer;
