import React, { useCallback, useEffect, useState } from "react";
import { IonToast } from "@ionic/react";
import PageLayout from "../../layouts/PageLayout";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import http from "axios";
import firebase from "firebase";
import ErrorMessage from "components/ErrorMessage";
import Title from "components/Title";
import Input from "components/Input";
import Button from "components/Button";

interface MessageAccountsBody {
  string_name: string;
}

interface MessageAccountsResponse {
  result: string;
}

const MessageAccounts: React.FC<{}> = () => {
  const [stringName, setStringName] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [toastMessage, setToastMessage] = useState<string>("");

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", {
      page: "admin_message_accounts",
    });
  }, []);

  const submit = useCallback(
    (form) => {
      if (form) {
        form.preventDefault();
      }
      firebase.analytics().logEvent("admin_message_accounts", {});
      FirebaseAnalytics.logEvent("admin_message_accounts", {});

      setError("");
      setToastMessage("");

      const body: MessageAccountsBody = {
        string_name: stringName,
      };

      http
        .post<MessageAccountsResponse>(`/v1/admin/messageaccounts`, body)
        .then((result) => {
          setToastMessage(result.data.result);
        })
        .catch((e) => {
          setError(e.message);
        });
    },
    [stringName]
  );

  return (
    <PageLayout showEnvironment={true}>
      <Title title="Message Accounts" />
      <form onSubmit={(e) => submit(e)}>
        <div className="basis-full">
          <Input
            className="my-4"
            id="stringName"
            data-testid="stringName-input"
            label=""
            inputProps={{
              value: stringName,
              onChange: (e: any) => setStringName(e.target.value),
            }}
            type="text"
            placeholder="i18n String Name"
          />
        </div>
        <Button type="submit" disabled={stringName === ""} data-testid="submit">
          Set Message
        </Button>
        <ErrorMessage isText error={error} />
        <IonToast
          color="success"
          isOpen={!!toastMessage.length}
          onDidDismiss={() => setToastMessage("")}
          message={toastMessage}
          duration={5000}
        />
      </form>
    </PageLayout>
  );
};

export default MessageAccounts;
